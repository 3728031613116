import React from "react";
import Grid from "@mui/material/Grid";
import {FormInputText} from "../../../components/form/FormInputText";
import {FormInputRadioCore} from "../../../components/form/FormInputRadioCore";
import {FormInputDropdownDF} from "../../../components/form/FormInputDropdownDF";
import {FormInputDropdownDC} from "../../../components/form/FormInputDropdownDC";
import {ISaveProductForm} from "../ISaveProductForm";
import {FormInputSliderCPU} from "../../../components/form/FormInputSliderCPU";
import {FormInputSliderMemory} from "../../../components/form/FormInputSliderMemory";

export const Step1IdentificationContainer: React.FC<ISaveProductForm> = (
    {control, updateId, setValue}) => {

    if (control != null) {
        return (

            <Grid container spacing={2}>
                <Grid item md={12}>
                    <FormInputText name="name" control={control} label="Nom" disabled={updateId != undefined}/>
                </Grid>
                <Grid item md={12}>
                    <FormInputText name="numeroVersion" control={control} label="Version" disabled={updateId != undefined}/>
                </Grid>
                <Grid item md={12}>
                    <FormInputText name="numeroVersionTemplate" control={control} label="Version du template"/>
                </Grid>
                <Grid item md={12}>
                    <FormInputText name="numeroVersionBackend" control={control} label="Version du backend"/>
                </Grid>
                <Grid item md={12}>
                    <FormInputText name="numeroVersionFrontend" control={control} label="Version du frontend"/>
                </Grid>
                <Grid item md={12}>
                    <FormInputRadioCore name="deploy" control={control} label="Déployer" disabled={updateId != undefined}/>
                </Grid>
                <Grid item md={12}>
                    <FormInputText name="serverAlias" control={control} label="serverAlias" disabled={updateId != undefined}/>
                </Grid>
                <Grid item md={6}>
                    <FormInputDropdownDF name="defaultLanguage" control={control} label="Langue"/>
                </Grid>
                <Grid item md={6}>
                    <FormInputDropdownDC name="defaultCurrency" control={control} label="Devise"/>
                </Grid>
                <Grid item md={12}>
                    <FormInputSliderCPU minName="resources.requestsCpu" maxName="resources.limitsCpu" control={control} label={"CPU"} setValue={setValue}/>
                </Grid>
                <Grid item md={12}>
                    <FormInputSliderMemory minName="resources.requestsMemory" maxName="resources.limitsMemory" control={control} label={"Memory"} setValue={setValue}/>
                </Grid>

            </Grid>
        )
    } else {
        return <></>;
    }


}